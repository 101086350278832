import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const headerCSS = css`
  scroll-behavior: smooth;
`;

const content = css`
  min-height: ${contentHeight}vh;
`;

const titleStyles = css`
  font-weight: 500;
`

const listStyles = css`
  list-style: none;
  margin-left: 0;
`;

const anchorStyles = css`
  color: #000000;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});

const Articles = () => {
  const data = useStaticQuery(graphql`
    query ArticlesQuery {
      allPrismicArticle(sort: { fields: [data___date], order: [DESC] }) {
        nodes {
          id
          uid
          data {
            title {
              text
            }
            date
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1 id="top" css={headerCSS}>
          Articles
        </h1>
        <ul css={listStyles}>
          {data.allPrismicArticle.nodes.map(node => {
            const date = new Date(node.data.date);
            const formattedDate = dateTimeFormat.format(date);

            return (
              <li key={node.id}>
                <h3 css={titleStyles}>
                  <Link
                    to={`/articles/${node.uid}/`}
                    css={anchorStyles}
                  >
                    {node.data.title.text}
                  </Link>
                </h3>
                <time
                  css={css`color: rgba(0, 0, 0, 0.5);`}
                >
                  {formattedDate}
                </time>
              </li>
            );
          })}
        </ul>
      </Container>
    </Layout>
  );
};

export default Articles;
